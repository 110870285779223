import React, { useEffect, useState } from "react";
import {
  Carousel,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Accordion,
  AccordionHeader,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  AccordionBody,
} from "@material-tailwind/react";
import { BlobProvider } from "@react-pdf/renderer";
import Frameheader from "./PDFView";
import { Download, Paperclip, Pencil } from "lucide-react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import OtpInput from "react-otp-input";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import pdfTemplate from "../documents/pdfTemplate.js";
var html2pdfJs = require("html2pdf.js");

const Desktop = ({ isMobile }) => {
  const [quotation, setQuotation] = useState();
  const [paymentInfo, setpaymentInfo] = useState();
  const [discountFlag, setDiscountFlag] = useState(false);
  const [showPayReceipt, setshowPayReceipt] = useState(false);
  const [discount, setDiscount] = useState();
  const [initialDiscount, setInitialDiscount] = useState();
  const [loading, setLoading] = useState(true);
  const [dateOfIssue, setDateOfIssue] = useState();
  const [allItemsChecked, setAllItemsChecked] = useState(false);
  const [showAttachments, setshowAttachments] = useState(false);
  const [attachmentFlag, setAttachmentFlag] = useState(false);
  const [paymentTermsFlag, setPaymentTermsFlag] = useState(false);
  const [showTncDialog, setShowTncDialog] = useState(false);
  const [showPaymentTerms, setShowPaymentTerms] = useState(false);
  const [showOtpDialog, setShowOtpDialog] = useState(false);
  const [termAccepted, setTermAccepted] = useState(true);
  const [otp, setOtp] = useState("");
  const [raising, setRaising] = useState(false);
  const [phoneNo, setPhoneNo] = useState();
  const [notFound, setNotFound] = useState(false);
  const [raised, setRaised] = useState(false);
  const [showRaisedDialog, setShowRaisedDialog] = useState(false);
  const [wrongOtp, setWrongOtp] = useState(false);
  const [localId, setLocalId] = useState(
    localStorage.getItem("localId") || "NA"
  );
  const [scrollStart, setScrollStart] = useState(0);
  const [scrollEnd, setScrollEnd] = useState(0);
  // Mobile only

  const [accOpen, setAccOpen] = useState([]);
  const [showMoreDialog, setShowMoreDialog] = useState(false);
  const [showMoreHeader, setShowMoreHeader] = useState("");
  const [showMoreBody, setShowMoreBody] = useState("");
  const [openMenu, setOpenMenu] = React.useState(false);
  const [bdDetails, setBdDetails] = useState(null);

  //pdf download
  const [downloadingPdf, setDownloadingPdf] = useState(false);
  const downloadPDF = async () => {
    setDownloadingPdf(true);
    let updatedQuotation;
    if (discountFlag) {
      updatedQuotation = {
        ...quotation,
        discountInRupees: discount.discountInRupees,
        discountInPercent: discount.discountInPercent,
        discountedAmount: discount.discountedAmount,
      };
    } else {
      updatedQuotation = quotation;
    }

    if (paymentTermsFlag) {
      const newPaymentTerms = quotation.paymentTerms;

      for (let i = 0; i < 5; i++) {
        newPaymentTerms[i].paymentTermsAmount =
          ((quotation.totalAmount -
            (quotation.totalAmount *
              parseFloat(getDiscount().discountInPercent)) /
              100) *
            quotation.paymentTerms[i].paymentTermsPercentage) /
          100;
      }

      updatedQuotation = { ...updatedQuotation, paymentTerms: newPaymentTerms };
    }
    const quotations = [updatedQuotation];

    try {
      const template = pdfTemplate(quotations);
      var opt = {
        margin: 0,
        filename: `${quotation.queryDetails.establishmentname}_${quotation.quotationId}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      };

      await html2pdfJs().set(opt).from(template).save();
    } catch (error) {
      console.error(error);
    } finally {
      setDownloadingPdf(false);
    }
  };

  const logEvent = async (event, row, action, btn) => {
    const link = window.location.href;
    const secureId = window.location.pathname.split("/").pop();
    try {
      let product = "NA";
      if (row !== "NA") {
        product =
          quotation.itemDetails[row].sku +
          " - " +
          quotation.itemDetails[row].name;
      }
      const response = await fetch("/logEvent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          secureId: secureId,
          row: row,
          product: product,
          action: action,
          button: btn,
          localId: localId,
          device: isMobile ? "mobile" : "desktop",
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to log event");
      }
    } catch (error) {
      console.log("Error logging event:", error);
    }
  };
  const bdList = {
    "divya.prakash@zoplar.com": {
      phonetext: "Divya Prakash",
      tel: "9311022190",
      src: "https://t4.ftcdn.net/jpg/01/67/00/35/360_F_167003593_J93oat8XYYPTeyGmtAR62UPmvgjs8thH.jpg",
      phonetext1: "Manager",
    },
    "krishna.jha@zoplar.com": {
      phonetext: "Krishna Jha",
      tel: "9311022192",
      src: "https://t4.ftcdn.net/jpg/01/67/00/35/360_F_167003593_J93oat8XYYPTeyGmtAR62UPmvgjs8thH.jpg",
      phonetext1: "Sales Manager",
    },
    "rishabh.shukla@zoplar.com": {
      phonetext: "Rishabh Shukla",
      tel: "9319178870",
      src: "https://t4.ftcdn.net/jpg/01/67/00/35/360_F_167003593_J93oat8XYYPTeyGmtAR62UPmvgjs8thH.jpg",
      phonetext1: "Sales Manager",
    },
    "shaik.sajid@zoplar.com": {
      phonetext: "Shaik Sajid",
      tel: "9311074761",
      src: "https://t4.ftcdn.net/jpg/01/67/00/35/360_F_167003593_J93oat8XYYPTeyGmtAR62UPmvgjs8thH.jpg",
      phonetext1: "Sales Manager",
    },
    "ankur.kumar@zoplar.com": {
      phonetext: "Ankur Kumar",
      tel: "9312085061",
      src: "https://t4.ftcdn.net/jpg/01/67/00/35/360_F_167003593_J93oat8XYYPTeyGmtAR62UPmvgjs8thH.jpg",
      phonetext1: "Sales Executive",
    },
    "pulkit.sharma@zoplar.com": {
      phonetext: "Pulkit Sharma",
      tel: "8447911523",
      src: "https://t4.ftcdn.net/jpg/01/67/00/35/360_F_167003593_J93oat8XYYPTeyGmtAR62UPmvgjs8thH.jpg",
      phonetext1: "Assistant Manager",
    },
    "abhishek.bhargava@zoplar.com": {
      phonetext: "Abhishek Bhargava",
      tel: "8130317110",
      src: "https://t4.ftcdn.net/jpg/01/67/00/35/360_F_167003593_J93oat8XYYPTeyGmtAR62UPmvgjs8thH.jpg",
      phonetext1: "Team Lead",
    },
    "gagandeep.saini@zoplar.com": {
      phonetext: "Gagandeep Saini",
      tel: "9311022191",
      src: "https://t4.ftcdn.net/jpg/01/67/00/35/360_F_167003593_J93oat8XYYPTeyGmtAR62UPmvgjs8thH.jpg",
      phonetext1: "Manager",
    },
    "arun.kumar@zoplar.com": {
      phonetext: "Arun Kumar",
      tel: "9560105204",
      src: "https://t4.ftcdn.net/jpg/01/67/00/35/360_F_167003593_J93oat8XYYPTeyGmtAR62UPmvgjs8thH.jpg",
      phonetext1: "Sales Executive",
    },
    "mukesh.yadav@zoplar.com": {
      phonetext: "Mukesh Yadav",
      tel: "8130315392",
      src: "https://t4.ftcdn.net/jpg/01/67/00/35/360_F_167003593_J93oat8XYYPTeyGmtAR62UPmvgjs8thH.jpg",
      phonetext1: "M. R.",
    },
    "sachin.seth@zoplar.com": {
      phonetext: "Sachin Seth",
      tel: "9311072696",
      src: "https://t4.ftcdn.net/jpg/01/67/00/35/360_F_167003593_J93oat8XYYPTeyGmtAR62UPmvgjs8thH.jpg",
      phonetext1: "B. D. M.",
    },
    "prafull.mishra.cn@zoplar.com": {
      phonetext: "Praful Mishra",
      tel: "9311022193",
      src: "https://t4.ftcdn.net/jpg/01/67/00/35/360_F_167003593_J93oat8XYYPTeyGmtAR62UPmvgjs8thH.jpg",
      phonetext1: "Sales Executive",
    },
    "sachin.gupta@zoplar.com": {
      phonetext: "Sachin Gupta",
      tel: "9319178871",
      src: "https://t4.ftcdn.net/jpg/01/67/00/35/360_F_167003593_J93oat8XYYPTeyGmtAR62UPmvgjs8thH.jpg",
      phonetext1: "Sales Executive",
    },
  };

  const location = useLocation();
  // const { id } = useParams();

  // useEffect(() => {
  //   if (location.search && location.search.startsWith("?razorpay_payment_id=pay_")) {
  //     console.log(location.search);
  //     handlePayReceipt();
  //   }
  // }, [location]);

  const parseQueryString = (search) => {
    const params = new URLSearchParams(search);
    const queryParams = {};

    params.forEach((value, key) => {
      if (!key.startsWith("razorpay_signature")) {
        const cleanedKey = key.startsWith("razorpay_")
          ? key.substring("razorpay_".length)
          : key;
        queryParams[cleanedKey] = value;
      }
    });

    return queryParams;
  };

  // Parse the location search string
  const queryParams = parseQueryString(location.search);
  // console.log(queryParams);

  const fetchData = async () => {
    const id = window.location.pathname.split("/").pop();
    try {
      const response = await fetch(`/api/quotations/${id}`);
      if (!response.ok) {
        console.error("Fetching failed: ", response);
        setNotFound(true);
        throw new Error("Failed to fetch quotation");
      }
      const responseText = await response.text();
      let quotation = JSON.parse(responseText);
      logEvent("NA", "NA", "quotation_open", "fetch");
      // version control
      const latestQuoteResponse = await fetch(`/api/getLatestQuotation/${id}`);
      if (latestQuoteResponse.ok) {
        const latestResponseText = await latestQuoteResponse.text();
        let latestQuotation = JSON.parse(latestResponseText);
        if (latestQuotation.quotationId !== quotation.quotationId) {
          const newId = latestQuotation.quotationLink.split("/").pop();
          window.history.replaceState(null, null, `/quotation/${newId}`);
          quotation = latestQuotation;
          logEvent("NA", "NA", "quotation_redirected_new_version", "fetch");
        }
      }
      setQuotation(quotation);
      const dateOfIssue = new Date(quotation.createdAt);
      setDateOfIssue(dateOfIssue);
      if (
        quotation.discountInPrecent != "0" &&
        quotation.discountInRupees != "0"
      ) {
        setDiscountFlag(true);
        setDiscount({
          discountInPercent: quotation.discountInPercent,
          discountInRupees: quotation.discountInRupees,
          discountedAmount: quotation.discountedAmount,
        });
      }
      setInitialDiscount({
        discountInPercent: quotation.discountInPercent,
        discountInRupees: quotation.discountInRupees,
        discountedAmount: quotation.discountedAmount,
      });
      if (quotation.attachmentDetails.length > 0) {
        setAttachmentFlag(true);
      }
      setPaymentTermsFlag(quotation.isPaymentTable);
      let phoneNumber = quotation.queryDetails.phonePoc;
      phoneNumber = phoneNumber.replace(/\D/g, "");
      if (phoneNumber.startsWith("91") && phoneNumber.length > 10) {
        phoneNumber = phoneNumber.substring(2);
      }
      setPhoneNo(phoneNumber);
      setRaised(quotation.PO);
      setShowRaisedDialog(quotation.PO);
      const initialAccOpen = Array(quotation.itemDetails.length).fill(false);
      setAccOpen(initialAccOpen);
      const quotationBd = quotation.queryDetails.email;
      if (isMobile && bdList[quotationBd]) {
        setBdDetails(bdList[quotationBd]);
      }
    } catch (error) {
      console.error("Error: ", error);
    } finally {
      setLoading(false);
    }
  };
  const getDiscount = () => {
    let costPriceOriginal = 0,
      costPriceChecked = 0,
      sellingPriceOriginal = 0,
      sellPriceChecked = 0;

    quotation.itemDetails.forEach((item, index) => {
      costPriceOriginal += parseFloat(item.cpegst * item.quantity);
      sellingPriceOriginal += parseFloat(item.spegst * item.quantity);
      if (raised ? item.po_check : item.check) {
        costPriceChecked += parseFloat(item.cpegst * item.quantity);
        sellPriceChecked += parseFloat(item.spegst * item.quantity);
      }
    });

    let averageMarginOriginal = 0,
      averageMarginChecked = 0;

    averageMarginChecked =
      ((sellPriceChecked - costPriceChecked) / sellPriceChecked) * 100;
    averageMarginOriginal =
      ((sellingPriceOriginal - costPriceOriginal) / sellingPriceOriginal) * 100;

    let delta = averageMarginChecked - averageMarginOriginal;
    let discountInRupees = 0,
      discountInPercent = 0,
      discountedAmount = 0;
    if (delta >= 0) {
      discountInPercent = parseFloat(initialDiscount.discountInPercent);
      discountInRupees =
        (parseFloat(quotation.totalAmount) *
          parseFloat(initialDiscount.discountInPercent)) /
        100;
      discountedAmount = parseFloat(quotation.totalAmount) - discountInRupees;
    } else {
      if (Math.abs(delta) < parseFloat(initialDiscount.discountInPercent)) {
        discountInPercent =
          parseFloat(initialDiscount.discountInPercent) -
          Math.abs(delta).toFixed(1);
        discountInRupees =
          (parseFloat(quotation.totalAmount) * discountInPercent) / 100;
        discountedAmount = parseFloat(quotation.totalAmount) - discountInRupees;
      } else {
        discountInPercent = 0;
        discountInRupees = 0;
        discountedAmount = 0;
      }
    }

    return {
      discountInPercent: discountInPercent.toString(),
      discountInRupees: discountInRupees.toString(),
      discountedAmount: discountedAmount.toString(),
    };
  };

  useEffect(() => {
    const scrollPercentage = Math.round(
      (window.scrollY / (document.body.scrollHeight - window.innerHeight)) * 100
    );
    logEvent(
      "NA",
      "NA",
      `scroll_till: ${scrollPercentage}% - ${scrollEnd}`,
      "scroll"
    );
  }, [scrollEnd]);

  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setScrollEnd(window.scrollY);
      }, 1000); // Adjust the debounce time
    };

    const handleScrollStart = () => {
      setScrollStart(window.scrollY);
      handleScroll();
    };

    window.addEventListener("scroll", handleScrollStart);
    setLoading(true);
    fetchData();
    return () => {
      window.removeEventListener("scroll", handleScrollStart);
    };
  }, []);

  const formatIndianNumber = (num) => {
    num = parseFloat(num);
    let formattedNum;
    if (num < 20) {
      formattedNum = num.toFixed(2);
    } else if (num <= 100) {
      formattedNum = num.toFixed(1);
    } else {
      formattedNum = num.toFixed(0);
      var x = formattedNum;
      x = x.toString();
      var lastThree = x.substring(x.length - 3);
      var otherNumbers = x.substring(0, x.length - 3);
      if (otherNumbers != "") lastThree = "," + lastThree;
      var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      return "₹" + res;
    }
    return "₹" + formattedNum;
  };

  useEffect(() => {
    if (quotation && quotation.itemDetails) {
      let newTotalAmount = 0;
      let allChecked = true;
      quotation.itemDetails.forEach((item) => {
        if (raised ? item.po_check : item.check) {
          newTotalAmount += item.spigst * item.quantity;
        } else {
          allChecked = false;
        }
      });
      setAllItemsChecked(allChecked);
      if (quotation.totalAmount !== newTotalAmount) {
        setQuotation((prevQuotation) => {
          const newQuotation = { ...prevQuotation };
          newQuotation.totalAmount = newTotalAmount;
          return newQuotation;
        });
      }

      if (discountFlag) {
        const newDiscount = getDiscount();
        setDiscount(newDiscount);
      }
    }
  }, [quotation]);

  const handleItemCheck = (e, item, index) => {
    const updatedItem = { ...item, check: !item.check };
    setQuotation((prevQuotation) => {
      const newQuotation = { ...prevQuotation };
      newQuotation.itemDetails[index] = updatedItem;
      return newQuotation;
    });
    logEvent(
      e,
      index,
      !item.check ? "item_check" : "item_uncheck",
      "input_checkbox"
    );
  };

  const handleAllItemsCheck = (e) => {
    setQuotation((prevQuotation) => {
      const newQuotation = { ...prevQuotation };
      newQuotation.itemDetails = newQuotation.itemDetails.map((item) => {
        return { ...item, check: !allItemsChecked };
      });
      return newQuotation;
    });
    setAllItemsChecked(!allItemsChecked);
    logEvent(
      e,
      "NA",
      !allItemsChecked ? "all_items_check" : "all_items_uncheck",
      "input_checkbox"
    );
  };

  const handleQuantityChange = (e, item, index) => {
    const updatedQuantity = e.target.value;
    setQuotation((prevQuotation) => {
      const newQuotation = { ...prevQuotation };
      newQuotation.itemDetails[index] = {
        ...item,
        quantity: updatedQuantity === "" ? "" : parseInt(updatedQuantity),
      };
      return newQuotation;
    });
    logEvent(e, index, "quantity_change_manually", "input_number");
  };

  const handleQuantityBlur = (e, item, index) => {
    const updatedQuantity = parseInt(e.target.value, 10);
    if (isNaN(updatedQuantity) || updatedQuantity < 1) {
      setQuotation((prevQuotation) => {
        const newQuotation = { ...prevQuotation };
        newQuotation.itemDetails[index] = { ...item, quantity: 1 };
        return newQuotation;
      });
      logEvent(e, index, "quantity_blur", "auto");
    }
  };

  const handleQuantityChangeButton = (e, item, index, operation) => {
    setQuotation((prevQuotation) => {
      const newQuotation = { ...prevQuotation };
      let updatedQuantity = parseInt(item.quantity);
      if (operation === "+") {
        updatedQuantity += 1;
      } else if (operation === "-" && updatedQuantity > 1) {
        updatedQuantity -= 1;
      }
      newQuotation.itemDetails[index] = {
        ...item,
        quantity: updatedQuantity.toString(),
      };
      return newQuotation;
    });
    logEvent(
      e,
      index,
      operation === "+" ? "quantity_increase" : "quantity_decrease",
      "button"
    );
  };

  const handleDownloadPdf = () => {
    let updatedQuotation;
    if (discountFlag) {
      updatedQuotation = {
        ...quotation,
        discountInRupees: discount.discountInRupees,
        discountInPercent: discount.discountInPercent,
        discountedAmount: discount.discountedAmount,
      };
    } else {
      updatedQuotation = quotation;
    }

    if (paymentTermsFlag) {
      const newPaymentTerms = quotation.paymentTerms;

      for (let i = 0; i < 5; i++) {
        newPaymentTerms[i].paymentTermsAmount =
          ((quotation.totalAmount -
            (quotation.totalAmount *
              parseFloat(getDiscount().discountInPercent)) /
              100) *
            quotation.paymentTerms[i].paymentTermsPercentage) /
          100;
      }

      updatedQuotation = { ...updatedQuotation, paymentTerms: newPaymentTerms };
    }
    return (
      <PDFDownloadLink
        document={<Frameheader quotations={[updatedQuotation]} />}
        fileName={`${quotation.queryDetails.establishmentname}_${quotation.quotationId}.pdf`}
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            isMobile ? (
              "Loading..."
            ) : (
              "Loading..."
            )
          ) : (
            <div className="flex flex-row">
              <span className="flex items-center">
                {isMobile ? "PDF" : "Download PDF"}
              </span>
              <Download className="w-4 ml-2 text-red-300" />
            </div>
          )
        }
      </PDFDownloadLink>
    );
  };

  const handleAttachmentsDialog = () => {
    const current = showAttachments;
    setshowAttachments(!current);
  };
  const handlePayReceipt = () => {
    const current = showPayReceipt;
    setshowPayReceipt(!current);
  };
  const downloadFile = (url, filename) => {
    logEvent("NA", "NA", "attachment_download", "link");
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = new Blob([xhr.response], {
          type: xhr.getResponseHeader("content-type"),
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };
    xhr.send();
  };

  const tncPoints = [
    "This quotation supersedes all other quotations previously exchanged between the parties.",
    "The order will be placed under the official name of Savdo Technologies Pvt Ltd.",
    "The validity of the quotation is contingent upon the availability of stocks at the time of confirmation.",
    "Please note that the prices quoted in this document do not include additional charges such as Insurance, Packaging, and Forwarding (P&F) Charges. These charges may vary depending on the specific product and shipping address.",
    "Any applicable taxes, duties, delivery charges, levies, transportation fees, or similar charges will be considered extra and will be the sole responsibility of the purchaser.",
    "This quotation is valid for a period of 3 days from the date it was issued. After this period, the prices and availability of the products may be subject to change.",
    "As per our Return Policy, once the goods are sold, they cannot be returned unless otherwise stated or specified.",
    "Post-warranty service for medical equipment or machinery traded under this invoice will be provided at the sole discretion of Savdo Technologies Pvt Ltd or its authorised affiliates and will incur separate costs.",
    "In the event of any disputes arising from this quotation, all matters will be resolved through sole arbitration conducted by Savdo Technologies Pvt Ltd.",
    "Please be aware that the terms and conditions outlined in this document are subject to the jurisdiction and laws of Gurugram, ensuring adherence to the legal framework governing the region.",
    "We hereby confirm that we have obtained and maintained all the necessary licence(s) and registration(s) as required by the applicable laws, which are currently valid and in force as of the present date.",
  ];

  const handleTncDialog = () => {
    const current = showTncDialog;
    setShowTncDialog(!current);
    logEvent(
      "NA",
      "NA",
      current ? "terms_close" : "terms_open",
      "button/click"
    );
  };

  const handlePaymentTermsDialog = () => {
    const current = showPaymentTerms;
    setShowPaymentTerms(!current);
    logEvent(
      "NA",
      "NA",
      current ? "payment_terms_close" : "payment_terms_open",
      "button/click"
    );
  };

  const handleOtpDialog = () => {
    const current = showOtpDialog;
    setShowOtpDialog(!current);
  };
  useEffect(() => {
    if (showRaisedDialog) {
      handleUnraisedDialog();
    }
  }, [showRaisedDialog]);
  const handleUnraisedDialog = () => {
    let quotationId = quotation.quotationId;

    fetch(`/paymentInfo?quotationId=${quotationId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setpaymentInfo(data);
        console.log(data, "payinfo");
      })
      .catch((error) => console.error("Error:", error));
  };
  const handleAdvPaySuccess = () => {
    if (quotation && paymentInfo.status === "paid") {
      console.log(paymentInfo.status, "msg m");
      const quotationId = quotation.quotationId;
      const url = `/advPaySuccess/${quotationId}?phone_no=${phoneNo}`;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data); // Log response from server
          // Optionally, update your UI or notify the user of success
        })
        .catch((error) =>
          console.error("Error updating advPayMsgSent:", error)
        );
    } else {
      console.error("Quotation is not available.");
    }
  };

  useEffect(() => {
    if (quotation && paymentInfo && !quotation.advPayMsgSent) {
      console.log("here");
      handleAdvPaySuccess();
    }
  }, [quotation, paymentInfo]);

  const handleRaisedDialog = () => {
    const current = showRaisedDialog;
    setShowRaisedDialog(!current);
  };
  const handleShowMoreDialog = () => {
    const current = showMoreDialog;
    setShowMoreDialog(!current);
  };

  const sendOTP = () => {
    const requestData = {
      phone_no: phoneNo,
    };

    fetch("/sendOTP", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        response.json();
      })
      .then((data) => {})
      .catch((error) => console.error("Error:", error));
  };

  const verifyOTP = () => {
    setRaising(true);
    setWrongOtp(false);
    let updatedQuotation;
    if (discountFlag) {
      updatedQuotation = {
        ...quotation,
        discountInRupees: discount.discountInRupees,
        discountInPercent: discount.discountInPercent,
        discountedAmount: discount.discountedAmount,
      };
    } else {
      updatedQuotation = quotation;
    }

    const newPaymentTerms = quotation.paymentTerms;

    for (let i = 0; i < 5; i++) {
      newPaymentTerms[i].paymentTermsAmount =
        ((quotation.totalAmount -
          (quotation.totalAmount *
            parseFloat(getDiscount().discountInPercent)) /
            100) *
          quotation.paymentTerms[i].paymentTermsPercentage) /
        100;
    }

    updatedQuotation = { ...updatedQuotation, paymentTerms: newPaymentTerms };

    let currTotalAmount = quotation.totalAmount.toString();
    updatedQuotation = { ...updatedQuotation, totalAmount: currTotalAmount };
    let requestData = {
      phone_no: phoneNo,
      otp_code: otp,
      quantityChanges: {},
      checkboxChanges: {},
      quotations: [updatedQuotation],
    };

    updatedQuotation.itemDetails.forEach((item) => {
      requestData.quantityChanges[item.sku] = item.quantity;
      requestData.checkboxChanges[item.sku] = item.check;
    });
    fetch("/verifyOTP", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.startsWith("success")) {
          console.log(data);
          handleOtpDialog();
          setRaised(true);
          setRaising(false);
          setShowRaisedDialog(true);
          logEvent("NA", "NA", "order_raised", "response");
        } else {
          console.log("wrong response");
          console.log(data);
          if (data.includes("incorrect")) {
            setWrongOtp(true);
          }
          setRaising(false);
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  function Icon({ open }) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className={`${open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
        />
      </svg>
    );
  }

  const handleAccordianChange = (index) => {
    const newAccOpen = [...accOpen];
    newAccOpen[index] = !newAccOpen[index];
    setAccOpen(newAccOpen);
    logEvent(
      "NA",
      index,
      newAccOpen[index] ? "expand" : "collapse",
      "button/click"
    );
  };

  return (
    <div>
      {!loading && !notFound && (
        <Helmet>
          <title>
            Quotation for {quotation.queryDetails.establishmentname}
          </title>
          {/* <meta name="description" content={metadata.description} /> */}
          <meta
            property="og:title"
            content={quotation.queryDetails.establishmentname}
          />
          <meta
            property="og:description"
            content={quotation.queryDetails.phonePoc}
          />
        </Helmet>
      )}
      {/* Desktop */}
      {!isMobile && (
        <div className="hidden md:block">
          {/* header */}
          <div
            className="w-full flex flex-row justify-between p-4 sticky top-0 z-50"
            style={{ backgroundImage: "url('/headerBg.png')" }}
          >
            <div>
              <img
                src="/logo-zoplar-png-1@2x.png"
                alt="Zoplar"
                className="w-40"
              />
            </div>
            {!loading && !notFound && (
              <div className="flex flex-row">
                <div className="mr-2">
                  {!discountFlag && (
                    <div>
                      <div>
                        <h3 className="font-semibold">Quotation Amount</h3>
                      </div>
                      <div>
                        <h1 className="font-bold text-lg">
                          {formatIndianNumber(quotation.totalAmount)}
                        </h1>
                      </div>
                    </div>
                  )}

                  {discountFlag && (
                    <div>
                      <div className="flex flex-row">
                        <h3 className="font-semibold line-through	text-gray-800">
                          {formatIndianNumber(quotation.totalAmount)}
                        </h3>
                        <h3 className="ml-2 text-xs flex items-center font-medium bg-orange-300 p-1 rounded-md">
                          {parseFloat(discount.discountInPercent).toFixed(2)}%
                          OFF
                        </h3>
                      </div>
                      <div>
                        <h1 className="font-bold text-lg">
                          {formatIndianNumber(discount.discountedAmount)}
                        </h1>
                      </div>
                    </div>
                  )}
                </div>
                <div className="ml-2 flex items-center">
                  {!raised && (
                    <Button
                      color="green"
                      size="lg"
                      className="bg-green-600 normal-case text-md font-bold py-2 px-4"
                      onClick={(e) => {
                        if (paymentTermsFlag) {
                          handlePaymentTermsDialog();
                        } else {
                          handleTncDialog();
                        }
                        logEvent(e, "NA", "raise_order_home", "button");
                      }}
                      disabled={
                        raised || parseFloat(quotation.totalAmount) === 0
                      }
                    >
                      Raise Order
                    </Button>
                  )}
                  {raised && (
                    <Button
                      color="green"
                      size="lg"
                      className="bg-gray-600 normal-case text-md font-bold py-2 px-4"
                      onClick={handleRaisedDialog}
                    >
                      Order Raised
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>

          {loading && <div>Loading...</div>}

          {notFound && <div>Not Found</div>}

          {!loading && !notFound && (
            <div className="p-16 pt-6">
              {/* details */}
              <div className="flex flex-row justify-between mb-4">
                <div>
                  <div className="mb-2">
                    <h1>
                      <span className="font-bold">Quotation for</span>{" "}
                      {quotation.queryDetails.pocName}
                    </h1>
                  </div>
                  <div>
                    <span className="font-bold">Date of Issue:</span>{" "}
                    {dateOfIssue.toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })}
                  </div>
                </div>

                <div className="flex flex-row">
                  <div className="mr-4 mb-2">
                    <h1>
                      <span className="font-bold">Ref. No.:</span>{" "}
                      {quotation.quotationId}{" "}
                    </h1>
                  </div>
                  <div>
                    <div className="mb-2">
                      {/* <button
                    className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-2 text-sm rounded shadow-lg"
                    onClick={handleDownloadPdf}
                  >
                    Download PDF
                  </button> */}

                  {/* COMMENT THIS */}
                    <div
                        className="bg-gray-200 hover:bg-gray-300 py-1 text-sm rounded shadow-lg w-36 flex justify-center border border-gray-400 text-blue-800 font-semibold"
                        onClick={(e) => {
                          logEvent(e, "NA", "pdf_download", "button");
                        }}
                      >
                        {quotation.totalAmount === 0
                          ? "Nothing selected"
                          : handleDownloadPdf()}
                      </div>

                  
                    {/* UNCOMMENT THIS */}
                      {/* <button
                        className="bg-gray-200 hover:bg-gray-300 py-1 text-sm rounded shadow-lg w-36 flex justify-center border border-gray-400 text-blue-800 font-semibold"
                        onClick={(e) => {
                          e.preventDefault();
                          downloadPDF();
                          logEvent(e, "NA", "pdf_download", "button");
                        }}
                        disabled={downloadingPdf}
                      >
                        {quotation.totalAmount === 0 ? (
                          "Nothing selected"
                        ) : (
                          <div className="flex flex-row">
                            <span className="flex items-center">
                              {downloadingPdf
                                ? "Downloading..."
                                : "Download PDF"}
                            </span>
                            {!downloadingPdf && (
                              <Download className="w-4 ml-2 text-red-300" />
                            )}
                          </div>
                        )}
                      </button> */}
                    </div>
                    {attachmentFlag && (
                      <div>
                        <button
                          className="bg-gray-200 hover:bg-gray-300 py-1 text-sm rounded shadow-lg w-36 flex justify-center border border-gray-400 text-blue-800 font-semibold"
                          onClick={handleAttachmentsDialog}
                        >
                          Attachments{" "}
                          <Paperclip className="w-4 ml-2 text-red-300" />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* check uncheck */}
              <div className="mb-4">
                <label
                  className={`inline-flex items-center cursor-pointer ${
                    raised ? "opacity-50" : ""
                  }`}
                >
                  <input
                    type="checkbox"
                    checked={allItemsChecked}
                    onChange={handleAllItemsCheck}
                    className="sr-only peer"
                    disabled={raised}
                  />
                  <div
                    className={`relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 ${
                      raised ? "bg-gray-300" : ""
                    }`}
                  ></div>
                  <span
                    className={`ms-3 text-sm font-medium ${
                      raised
                        ? "text-gray-500"
                        : "text-gray-900 dark:text-gray-300"
                    }`}
                  >
                    {allItemsChecked ? "Uncheck All Items" : "Check All Items"}
                  </span>
                </label>
              </div>

              {/* body */}

              <div>
                {quotation.itemDetails.map((item, index) => {
                  return (
                    <div
                      className="w-full rounded-md p-2 flex flex-row mb-4 bg-gray-50 shadow-sm hover:shadow-xl"
                      key={index}
                    >
                      {/* left */}
                      <div className="w-1/3 p-2 flex flex-row">
                        {/* image and checkbox */}
                        <div>
                          <div className="relative">
                            <div className="absolute z-40 mt-2 ml-2">
                              <input
                                type="checkbox"
                                checked={raised ? item.po_check : item.check}
                                onChange={(e) =>
                                  handleItemCheck(e, item, index)
                                }
                                className="w-5 h-5 rounded border-gray-300 text-blue-600 shadow-sm focus:ring-blue-500"
                                disabled={raised}
                              />
                            </div>
                          </div>
                          <div className="border border-gray-300 rounded-lg">
                            <Carousel className="h-52 w-52 z-30">
                              {item.image.length > 0 &&
                                item.image.map((img, index) => (
                                  <img
                                    src={img}
                                    alt={`Slide ${index}`}
                                    className="h-52 w-52 object-cover rounded-lg"
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = "/placeholder.jpeg";
                                    }}
                                  />
                                ))}
                              {item.image && item.image.length === 0 && (
                                <img
                                  src="/placeholder.jpeg"
                                  alt={`Slide ${index}`}
                                  className="h-52 w-52 object-cover rounded-lg"
                                />
                              )}
                            </Carousel>
                          </div>
                        </div>

                        {/* details */}

                        <div className="ml-2">
                          <div className="mb-2">
                            <h1 className="font-bold">{item.name}</h1>
                          </div>
                          <div>
                            <h2>
                              <span className="font-bold">Brand:</span>{" "}
                              {item.brand}
                            </h2>
                          </div>

                          <div className="mt-4">
                            <div>
                              <h2>
                                <span className="font-bold">
                                  Specifications:
                                </span>{" "}
                              </h2>
                            </div>

                            <div className="h-24 overflow-y-auto">
                              <p className="text-sm">
                                {(
                                  item.size +
                                  (item.warranty_duration !== "-"
                                    ? " WITH " + item.warranty_duration
                                    : "")
                                ).toUpperCase()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* right */}
                      <div className="w-2/3 border-l pl-2">
                        {/* right upper */}
                        <div className="flex flex-row justify-between mb-2">
                          {/* Price Per Unit (Excl. GST) */}
                          <div className="p-2">
                            <div className="mb-2">
                              <h3 className="font-bold">
                                Price Per Unit (Excl. GST)
                              </h3>
                            </div>
                            <div>
                              <p>{formatIndianNumber(item.spegst)}</p>
                            </div>
                          </div>

                          {/* GST */}
                          <div className="p-2">
                            <div className="mb-2">
                              <h3 className="font-bold">GST (%) </h3>
                            </div>
                            <div>
                              <p>{item.gst}</p>
                            </div>
                          </div>

                          {/* Price Per Unit (Incl. GST)   */}
                          <div className="p-2">
                            <div className="mb-2">
                              <h3 className="font-bold">
                                Price Per Unit (Incl. GST){" "}
                              </h3>
                            </div>
                            <div>
                              <p>{formatIndianNumber(item.spigst)}</p>
                            </div>
                          </div>

                          {/* Quantity  */}
                          <div className="p-2">
                            <div className="mb-2">
                              <h3 className="font-bold">Quantity</h3>
                            </div>
                            <div className="flex flex-row justify-center items-center">
                              <div className="w-6">
                                <button
                                  className="w-full bg-blue-600 rounded-tl-sm rounded-bl-sm text-white"
                                  onClick={(e) =>
                                    handleQuantityChangeButton(
                                      e,
                                      item,
                                      index,
                                      "-"
                                    )
                                  }
                                  disabled={raised}
                                >
                                  -
                                </button>
                              </div>
                              <div className="w-10 flex justify-center items-center">
                                <input
                                  type="text"
                                  value={item.quantity}
                                  className="w-10 text-center"
                                  onChange={(e) =>
                                    handleQuantityChange(e, item, index)
                                  }
                                  onBlur={(e) =>
                                    handleQuantityBlur(e, item, index)
                                  }
                                  disabled={raised}
                                />
                              </div>
                              <div className="w-6">
                                <button
                                  className="w-full bg-blue-600 rounded-tr-sm rounded-br-sm text-white"
                                  onClick={(e) =>
                                    handleQuantityChangeButton(
                                      e,
                                      item,
                                      index,
                                      "+"
                                    )
                                  }
                                  disabled={raised}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                          {/* Total Amount  */}
                          <div className="p-2">
                            <div className="mb-2">
                              <h3 className="font-bold">Total Amount</h3>
                            </div>
                            <div>
                              <p>
                                {formatIndianNumber(
                                  item.spigst * item.quantity
                                )}
                              </p>
                            </div>
                          </div>
                        </div>

                        {item.description != "" && (
                          <div className="border border-gray-300 p-2 rounded-md">
                            <div>
                              <h3 className="font-bold text-gray-600">
                                Product Features
                              </h3>
                            </div>

                            <div className="h-24 overflow-y-auto ml-2">
                              <p className="whitespace-pre-line text-sm text-gray-600">
                                {item.description === ""
                                  ? "NA"
                                  : item.description}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    
                  );
                })}
              </div>
              {parseFloat(quotation.deliveryCharges)>0 && (   <div style={{ 
                backgroundColor: "#f0f0f0",
                padding: "10px",
                marginTop: "10px",
                border: "1px solid #ccc",
                textAlign:"center"
              }}>
                Applicable delivery charges: {formatIndianNumber(parseFloat(quotation.deliveryCharges))}
              </div> )}
            </div>
            
          )}

          {/* dialogs */}

          {!loading && !notFound && (
            <div>
              {/* attachments */}
              {attachmentFlag && (
                <Dialog
                  open={showAttachments}
                  handler={handleAttachmentsDialog}
                >
                  <DialogHeader>Attachments</DialogHeader>
                  <DialogBody className="p-0 pl-4 pr-4">
                    <div>
                      <ul>
                        {quotation.attachmentDetails.map(
                          (attachment, index) => (
                            <li key={index}>
                              <a
                                href="#"
                                download={`${attachment.attachmentTitle}.pdf`}
                                onClick={(event) => {
                                  event.preventDefault();
                                  downloadFile(
                                    attachment.attachmentLink,
                                    attachment.attachmentTitle
                                  );
                                }}
                                className="text-blue-800"
                              >
                                {attachment.attachmentTitle}
                              </a>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </DialogBody>
                  <DialogFooter>
                    <Button
                      variant="gradient"
                      color="green"
                      onClick={handleAttachmentsDialog}
                    >
                      <span>Done</span>
                    </Button>
                  </DialogFooter>
                </Dialog>
              )}

              {/* Terms  & conditions */}
              <Dialog open={showTncDialog} handler={handleTncDialog} size="lg">
                <DialogHeader>Terms & Conditions</DialogHeader>
                <DialogBody className="p-0">
                  <div className="pl-10 pr-6">
                    <ul style={{ listStyleType: "disc" }}>
                      {tncPoints.map((point, index) => (
                        <li key={index}>{point}</li>
                      ))}
                    </ul>
                  </div>
                </DialogBody>
                <DialogFooter>
                  <Button
                    variant="gradient"
                    color="green"
                    onClick={() => {
                      handleTncDialog();

                      if (paymentTermsFlag && termAccepted) {
                        handleOtpDialog();
                      } else {
                        handlePaymentTermsDialog();
                      }

                      if (!paymentTermsFlag) {
                        sendOTP();
                        handleOtpDialog();
                      }
                    }}
                  >
                    <span>Understood</span>
                  </Button>
                </DialogFooter>
              </Dialog>

              {/* Payment terms */}

              {paymentTermsFlag && (
                <Dialog
                  open={showPaymentTerms}
                  handler={handlePaymentTermsDialog}
                  size="lg"
                >
                  <DialogHeader>Payment Terms</DialogHeader>
                  <DialogBody className="p-0">
                    <div className="pl-6 pr-6">
                      <div className="flex justify-center">
                        <table className="text-black border">
                          <thead className="text-center  bg-gray-400">
                            <tr>
                              <th className="p-2 border">Payment Terms</th>
                              <th className="p-2 border">Amount (Rs.)</th>
                              <th className="p-2 border">Percentage (%)</th>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            {quotation.paymentTerms[0].paymentTermsAmount >
                              0 && (
                              <tr>
                                <th className="bg-gray-300 border">Advance</th>
                                <td className="border">
                                  {formatIndianNumber(
                                    ((quotation.totalAmount -
                                      (quotation.totalAmount *
                                        parseFloat(
                                          getDiscount().discountInPercent
                                        )) /
                                        100) *
                                      quotation.paymentTerms[0]
                                        .paymentTermsPercentage) /
                                      100
                                  )}
                                </td>
                                <td className="border">
                                  {quotation.paymentTerms[0].paymentTermsPercentage.toFixed(
                                    2
                                  )}
                                  %
                                </td>
                              </tr>
                            )}
                            {quotation.paymentTerms[1].paymentTermsAmount >
                              0 && (
                              <tr>
                                <th className="bg-gray-300 border">
                                  On Delivery
                                </th>
                                <td className="border">
                                  {formatIndianNumber(
                                    ((quotation.totalAmount -
                                      (quotation.totalAmount *
                                        parseFloat(
                                          getDiscount().discountInPercent
                                        )) /
                                        100) *
                                      quotation.paymentTerms[1]
                                        .paymentTermsPercentage) /
                                      100
                                  )}
                                </td>
                                <td className="border">
                                  {quotation.paymentTerms[1].paymentTermsPercentage.toFixed(
                                    2
                                  )}
                                  %
                                </td>
                              </tr>
                            )}
                            {quotation.paymentTerms[2].paymentTermsAmount >
                              0 && (
                              <tr>
                                <th className="bg-gray-300 border">
                                  On Installation
                                </th>
                                <td className="border">
                                  {formatIndianNumber(
                                    ((quotation.totalAmount -
                                      (quotation.totalAmount *
                                        parseFloat(
                                          getDiscount().discountInPercent
                                        )) /
                                        100) *
                                      quotation.paymentTerms[2]
                                        .paymentTermsPercentage) /
                                      100
                                  )}
                                </td>
                                <td className="border">
                                  {quotation.paymentTerms[2].paymentTermsPercentage.toFixed(
                                    2
                                  )}
                                  %
                                </td>
                              </tr>
                            )}
                            {quotation.paymentTerms[3].paymentTermsAmount >
                              0 && (
                              <tr>
                                <th className="bg-gray-300 border">
                                  {quotation.paymentTerms[3].duration} Days
                                  Credit
                                </th>
                                <td className="border">
                                  {formatIndianNumber(
                                    ((quotation.totalAmount -
                                      (quotation.totalAmount *
                                        parseFloat(
                                          getDiscount().discountInPercent
                                        )) /
                                        100) *
                                      quotation.paymentTerms[3]
                                        .paymentTermsPercentage) /
                                      100
                                  )}
                                </td>
                                <td className="border">
                                  {quotation.paymentTerms[3].paymentTermsPercentage.toFixed(
                                    2
                                  )}
                                  %
                                </td>
                              </tr>
                            )}
                            {quotation.paymentTerms[4].paymentTermsAmount >
                              0 && (
                              <tr>
                                <th className="bg-gray-300 border">
                                  {quotation.paymentTerms[4].duration} Months
                                  EMI
                                </th>
                                <td className="border">
                                  {formatIndianNumber(
                                    ((quotation.totalAmount -
                                      (quotation.totalAmount *
                                        parseFloat(
                                          getDiscount().discountInPercent
                                        )) /
                                        100) *
                                      quotation.paymentTerms[4]
                                        .paymentTermsPercentage) /
                                      100
                                  )}
                                </td>
                                <td className="border">
                                  {quotation.paymentTerms[4].paymentTermsPercentage.toFixed(
                                    2
                                  )}
                                  %
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="text-black flex items-center mt-2">
                        <label className="flex items-center">
                          <input
                            type="checkbox"
                            name="checkbox"
                            checked={termAccepted}
                            onChange={(e) => setTermAccepted(e.target.checked)}
                            className="mr-2 h-4 w-4"
                          />
                          I have read and agree to the{"  "}
                          <a
                            href="#"
                            className="text-blue-600 ml-1"
                            onClick={(e) => {
                              handlePaymentTermsDialog();
                              handleTncDialog();
                            }}
                          >
                            Terms & Conditions.
                          </a>
                        </label>
                      </div>
                    </div>
                  </DialogBody>
                  <DialogFooter>
                    <Button
                      variant="gradient"
                      color="green"
                      onClick={() => {
                        handlePaymentTermsDialog();
                        sendOTP();
                        handleOtpDialog();
                      }}
                      disabled={!termAccepted}
                    >
                      <span>Continue</span>
                    </Button>
                  </DialogFooter>
                </Dialog>
              )}

              {/* OTP */}
              <Dialog open={showOtpDialog} size="md">
                <DialogHeader className="text-black">Raise Order</DialogHeader>
                <DialogBody className="p-0">
                  {wrongOtp && (
                    <div className="flex flex-col justify-center items-center text-center mt-1">
                      <p className="text-red-600">Wrong OTP!</p>
                    </div>
                  )}
                  {raising && (
                    <div className="flex flex-col justify-center items-center text-center mt-1">
                      <p className="text-green-600">Raising Order...</p>
                      <p className="text-red-600">
                        Please do not refresh this page!
                      </p>
                    </div>
                  )}
                  <div className="pl-10 pr-6 flex flex-col justify-center">
                    <div>
                      <h1 className="text-center text-black">
                        An OTP has been sent to +91
                        {quotation.queryDetails.phonePoc}
                      </h1>
                    </div>
                    <div className="flex flex-col justify-center items-center text-center">
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderInput={(props) => (
                          <input
                            {...props}
                            style={{
                              marginLeft: "5px",
                              marginRight: "5px",
                              width: "40px",
                              height: "40px",
                              border: "1px solid #000000",
                              borderRadius: "5px",
                              padding: "2px",
                              textAlign: "center",
                            }}
                            disabled={raising}
                          />
                        )}
                        inputType="number"
                      />
                    </div>
                  </div>
                </DialogBody>
                <DialogFooter>
                  <Button
                    variant="outlined"
                    color="black"
                    onClick={(e) => {
                      handleOtpDialog();
                      setOtp("");
                      logEvent(e, "NA", "raise_order_cancel", "button");
                    }}
                    className="mr-2"
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button
                    variant="gradient"
                    color="green"
                    onClick={(e) => {
                      // handleOtpDialog();
                      verifyOTP();
                      logEvent(e, "NA", "verify_otp", "button");
                    }}
                  >
                    <span>Verify OTP</span>
                  </Button>
                </DialogFooter>
              </Dialog>

              {/* Raised */}

              {!loading && raised && (
                <Dialog
                  open={showRaisedDialog}
                  handler={handleRaisedDialog}
                  size="lg"
                >
                  <DialogBody className="p-0">
                    <div className="p-6 flex flex-col items-center justify-center">
                      <div className="flex flex-col items-center justify-center">
                        <div className="w-48">
                          <img
                            src="/2ccc867d887c1ca12670bab8084b0936.png"
                            alt="Success"
                          />
                        </div>
                        <div className="mt-4">
                          <h1 className="text-center text-black font-extrabold text-3xl">
                            Order Raised Successfully!
                          </h1>
                          <h2 className="text-center text-black font-semibold">
                            Our Team will connect with you soon.
                          </h2>
                          <p className="text-center text-black text-sm">
                            You can make any applicable advance payment to
                            confirm the order.
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-row mt-6">
                        <div
                          className="mr-8 justify-center flex flex-col text-black"
                          style={{ paddingRight: "25px" }}
                        >
                          <div className="mb-2">
                            <h1 className="font-bold">Account Details:</h1>
                          </div>
                          <div>
                            <p>
                              <strong className="font-bold">Name:</strong> Savdo
                              Technologies Pvt. Ltd.
                            </p>
                            <p>
                              <strong className="font-bold">
                                Account Number:
                              </strong>{" "}
                              50200069329058
                            </p>
                            <p>
                              <strong className="font-bold">IFS Code:</strong>{" "}
                              HDFC0003654
                            </p>
                          </div>
                        </div>

                        {paymentInfo && quotation ? (
                          quotation.paymentTerms[0].paymentTermsAmount <
                          500000 ? (
                            <div
                              onClick={() =>
                                (window.location.href = paymentInfo.short_url)
                              }
                              className="flex flex-row border border-gray-300 shadow-lg p-2 rounded-md cursor-pointer mt-4"
                            >
                              {paymentInfo.amount - paymentInfo.amount_paid >
                              0 ? (
                                <>
                                  <div
                                    className="w-20 h-20 flex items-center justify-center"
                                    style={{ padding: "10px" }}
                                  >
                                    <img src="/rzp.png" alt="rzp Logo" />
                                  </div>
                                  <div className="ml-4">
                                    <div className="mb-1">
                                      <h1
                                        className="text-lg font-semibold"
                                        style={{ color: "#0d94fb" }}
                                      >
                                        Pay with Razorpay
                                      </h1>
                                    </div>
                                    <div className="text-sm">
                                      <p>
                                        <span
                                          className="font-semibold"
                                          style={{ color: "#012652" }}
                                        >
                                          Due Advance: ₹
                                          {(paymentInfo.amount -
                                            paymentInfo.amount_paid) /
                                            100}
                                        </span>
                                      </p>
                                      <p>Pay advance now to confirm!</p>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="flex items-center justify-center w-20 h-20"
                                    style={{ padding: "10px" }}
                                  >
                                    <img src="/green-tick.png" alt="tick" />
                                  </div>
                                  <div className="ml-4 flex flex-col justify-center">
                                    <div className="mb-1">
                                      <h1
                                        className="text-lg font-semibold"
                                        style={{ color: "#00c04b" }}
                                      >
                                        Advance Paid!
                                      </h1>
                                    </div>
                                    <div className="text-sm">
                                      <p>
                                        <span
                                          className="font-semibold"
                                          style={{ color: "#012652" }}
                                        >
                                          Amount Paid: ₹
                                          {paymentInfo.amount_paid / 100}
                                        </span>
                                      </p>
                                      <p>Our team will reach you shortly.</p>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          ) : (
                            <div className="w-36 h-36">
                              <img
                                src="/QRCode.png"
                                alt="QR Code"
                                className="w-full h-full"
                              />
                            </div>
                          )
                        ) : (
                          <div className="text-sm">Loading...</div>
                        )}
                      </div>
                      <div className="mt-4">
                        {queryParams &&
                          Object.entries(queryParams).length > 0 && (
                            <p key={Object.entries(queryParams)[0][0]}>
                              Payment Id:{" "}
                              <strong>
                                {Object.entries(queryParams)[0][1]}
                              </strong>
                            </p>
                          )}
                      </div>
                    </div>
                  </DialogBody>
                  <DialogFooter>
                    <Button
                      variant="gradient"
                      color="green"
                      onClick={() => {
                        handleRaisedDialog();
                      }}
                    >
                      <span>Okay</span>
                    </Button>
                  </DialogFooter>
                </Dialog>
              )}

              {/* {(
                <Dialog
                  open={showPayReceipt}
                  handler={handlePayReceipt}
                  size="xxl"
                >
                  <DialogBody className="p-0">
                  <div className="max-w-full overflow-x-auto" style={{padding: '10px'}}>
                  <h2 className="text-lg font-semibold mb-4 text-center">Payment Receipt</h2>
                  <p className="text-md mb-4 text-center">You can optionally capture a screenshot of this payment receipt for your future reference.</p>
                  <table className="table-auto mx-auto border-collapse border border-black">
        <thead>
          <tr className="border border-black">
            <th className="border border-black px-4 py-2 text-left">Parameter</th>
            <th className="border border-black px-4 py-2 text-left">Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(queryParams).map(([key, value]) => (
            <tr key={key} className="border border-black">
              <td className="border border-black px-4 py-2">{key}</td>
              <td className="border border-black px-4 py-2 break-words">{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
                  </DialogBody>
                  <DialogFooter>
                    <Button
                    className="mx-auto"
                      variant="gradient"
                      color="green"
                      onClick={() => {
                        handlePayReceipt();
                      }}
                    >
                      <span>Okay</span>
                    </Button>
                  </DialogFooter>
                </Dialog>
              )} */}
            </div>
          )}
        </div>
      )}

      {/* Mobile */}
      {isMobile && (
        <div className="sm:block md:hidden">
          {loading && <div>Loading...</div>}
          {notFound && <div>Not found</div>}

          {!loading && !notFound && (
            <div>
              {/* header */}
              <div className="flex flex-row justify-between p-2 bg-slate-200">
                <div>
                  <img
                    src="/logo-zoplar-png-1@2x.png"
                    alt="Zoplar"
                    className="w-36"
                  />
                </div>
                <div className="text-xs">
                  <div>
                    <h1 className="font-semibold">
                      SAVDO TECHNOLOGIES PVT. LTD.
                    </h1>
                  </div>
                  <div>
                    <p className="text-right">
                      <a href="https://www.zoplar.com/">www.zoplar.com</a>
                    </p>
                    <p className="text-right">
                      <a href="mailto:support@zoplar.com">support@zoplar.com</a>
                    </p>
                  </div>
                </div>
              </div>

              {/* Details */}

              <div className="p-4 w-full flex flex-row">
                <div className="w-3/5">
                  <div>
                    <h1 className="text-sm font-semibold">
                      Quotation for {quotation.queryDetails.pocName}
                    </h1>
                  </div>
                  <div>
                    <h2 className="text-sm text-gray-500">
                      #{quotation.quotationId}
                    </h2>
                  </div>
                </div>
                <div className="w-2/5 text-right">
                  <label
                    className={`inline-flex items-center cursor-pointer ${
                      raised ? "opacity-50" : ""
                    }`}
                  >
                    <span
                      className={`ms-3 text-sm font-medium mr-2 ${
                        raised
                          ? "text-gray-500"
                          : "text-gray-900 dark:text-gray-300"
                      }`}
                    >
                      {allItemsChecked ? "Uncheck All" : "Check All"}
                    </span>
                    <input
                      type="checkbox"
                      checked={allItemsChecked}
                      onChange={handleAllItemsCheck}
                      className="sr-only peer"
                      disabled={raised}
                    />
                    <div
                      className={`relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 ${
                        raised ? "bg-gray-300" : ""
                      }`}
                    ></div>
                  </label>
                </div>
              </div>

              {/* body items */}
              <div className="p-4 pt-0 mb-20">
                {quotation.itemDetails.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="mb-4 p-2 rounded-md bg-gray-50 shadow-sm hover:shadow-xl"
                    >
                      <Accordion
                        open={accOpen[index]}
                        icon={
                          <div className="absolute top-0 right-0 m-2">
                            <Icon open={accOpen[index]} />
                          </div>
                        }
                      >
                        <AccordionHeader
                          onClick={() => handleAccordianChange(index)}
                          className="border-none m-0 p-0"
                        >
                          <div className="flex flex-row">
                            {/* left for image */}
                            <div
                              className={`border border-gray-300 rounded-lg flex-shrink-0 flex-grow-0 mt-1 transition-all duration-300 ${
                                accOpen[index] ? "h-40 w-40" : "h-28 w-28"
                              }`}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div className="relative">
                                <div className="absolute z-40 mt-2 ml-2">
                                  <input
                                    type="checkbox"
                                    checked={
                                      raised ? item.po_check : item.check
                                    }
                                    onChange={(e) =>
                                      handleItemCheck(e, item, index)
                                    }
                                    className="w-5 h-5 rounded border-gray-300 text-blue-600 shadow-sm focus:ring-blue-500"
                                    disabled={raised}
                                  />
                                </div>
                              </div>
                              <div className="h-fit w-fit">
                                <Carousel
                                  className={`${
                                    accOpen[index] ? "h-40 w-40" : "h-28 w-28"
                                  } z-30 transition-all duration-300 ease-in-out`}
                                >
                                  {item.image.length > 0 &&
                                    item.image.map((img, index) => (
                                      <img
                                        src={img}
                                        alt={`Slide ${index}`}
                                        className="h-full w-full object-cover rounded-lg"
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = "/placeholder.jpeg";
                                        }}
                                      />
                                    ))}
                                  {item.image && item.image.length === 0 && (
                                    <img
                                      src="/placeholder.jpeg"
                                      alt={`Slide ${index}`}
                                      className="h-full w-full object-cover rounded-lg"
                                    />
                                  )}
                                </Carousel>
                              </div>
                            </div>

                            {/* right for details */}

                            <div className="ml-2">
                              {/* always */}
                              <div>
                                <h1 className="text-base text-black">
                                  {item.name}
                                </h1>
                                <p className="text-sm mb-1">
                                  <span className="font-semibold">Brand: </span>
                                  <span className="font-normal">
                                    {item.brand}
                                  </span>
                                </p>
                              </div>

                              {/* conditional */}
                              {accOpen[index] && (
                                <div>
                                  {/* specs */}
                                  <div>
                                    <div className="mb-1">
                                      <p className="text-sm">
                                        <span className="font-semibold">
                                          Specification:{" "}
                                        </span>
                                        <span className="font-normal">
                                          {(
                                            item.size +
                                            (item.warranty_duration !== "-"
                                              ? " WITH " +
                                                item.warranty_duration
                                              : "")
                                          ).toUpperCase().length > 24
                                            ? (
                                                item.size +
                                                (item.warranty_duration !== "-"
                                                  ? " WITH " +
                                                    item.warranty_duration
                                                  : "")
                                              )
                                                .toUpperCase()
                                                .substring(0, 24) + "..."
                                            : (
                                                item.size +
                                                (item.warranty_duration !== "-"
                                                  ? " WITH " +
                                                    item.warranty_duration
                                                  : "")
                                              ).toUpperCase()}{" "}
                                        </span>
                                        {(
                                          item.size +
                                          (item.warranty_duration !== "-"
                                            ? " WITH " + item.warranty_duration
                                            : "")
                                        ).toUpperCase().length > 24 && (
                                          <button
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              const toSet = (
                                                item.size +
                                                (item.warranty_duration !== "-"
                                                  ? " WITH " +
                                                    item.warranty_duration
                                                  : "")
                                              ).toUpperCase();
                                              setShowMoreBody(toSet);
                                              setShowMoreHeader(
                                                "Specification"
                                              );
                                              handleShowMoreDialog();
                                            }}
                                            style={{
                                              color: "#2080c6",
                                              fontWeight: "normal",
                                            }}
                                          >
                                            (See More)
                                          </button>
                                        )}
                                      </p>
                                    </div>
                                  </div>

                                  {/* description */}
                                  {item.description && item.description !== "" && (
  <div>
    <div className="mb-1">
      <p className="text-sm">
        <span className="font-semibold">
          Description:{" "}
        </span>
        <span className="font-normal">
          {item.description.length > 16
            ? item.description.substring(0, 16) + "..."
            : item.description}{" "}
        </span>
        {item.description.length > 16 && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              setShowMoreHeader("Description");
              setShowMoreBody(item.description);
              handleShowMoreDialog();
            }}
            style={{
              color: "#2080c6",
              fontWeight: "normal",
            }}
          >
            (See More)
          </button>
        )}
      </p>
    </div>
  </div>
)}

                                </div>
                              )}
                              {!accOpen[index] && (
                                <div>
                                  <div className="mb-1">
                                    <p className="text-sm">
                                      <span className="font-semibold">
                                        Specs:{" "}
                                      </span>
                                      <span className="font-normal">
                                        {(
                                          item.size +
                                          (item.warranty_duration !== "-"
                                            ? " WITH " + item.warranty_duration
                                            : "")
                                        ).toUpperCase().length > 20
                                          ? (
                                              item.size +
                                              (item.warranty_duration !== "-"
                                                ? " WITH " +
                                                  item.warranty_duration
                                                : "")
                                            )
                                              .toUpperCase()
                                              .substring(0, 20) + "..."
                                          : (
                                              item.size +
                                              (item.warranty_duration !== "-"
                                                ? " WITH " +
                                                  item.warranty_duration
                                                : "")
                                            ).toUpperCase()}{" "}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="mb-1">
                                    <p className="text-sm flex flex-row">
                                      <span
                                        className="font-semibold"
                                        style={{
                                          color: "#2080c6",
                                        }}
                                      >
                                        Quantity:{" "}
                                      </span>
                                      <span className="font-normal ml-2">
                                        <input
                                          type="text"
                                          value={item.quantity}
                                          className="w-10 border rounded-md text-center"
                                          onChange={(e) =>
                                            handleQuantityChange(e, item, index)
                                          }
                                          onBlur={(e) =>
                                            handleQuantityBlur(e, item, index)
                                          }
                                          disabled={raised}
                                        />
                                      </span>
                                      <span className="flex items-center ml-1">
                                        <Pencil
                                          className="w-3 h-3"
                                          style={{
                                            color: "#2080c6",
                                          }}
                                        />
                                      </span>
                                    </p>
                                  </div>

                                  <div className="mb-1">
                                    <p className="text-sm ">
                                      <span className="font-semibold">
                                        Price/unit (incl. GST):{" "}
                                      </span>
                                      <span className="font-normal">
                                        {formatIndianNumber(item.spigst)}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                            
                          </div>
                        </AccordionHeader>
                        <AccordionBody>
                          <div className="w-full flex flex-row">
                            {/* left */}
                            <div className="w-3/5">
                              <div>
                                <p className="flex justify-between">
                                  <span className="font-semibold">
                                    Price/unit (excl. GST) :
                                  </span>
                                  <span>{formatIndianNumber(item.spegst)}</span>
                                </p>
                              </div>
                              <div>
                                <p className="flex justify-between">
                                  <span className="font-semibold">GST :</span>{" "}
                                  <span>{item.gst}</span>
                                </p>
                              </div>
                              <div>
                                <p className="flex justify-between">
                                  <span className="font-semibold">
                                    Price/unit (incl. GST) :
                                  </span>
                                  <span>{formatIndianNumber(item.spigst)}</span>
                                </p>
                              </div>
                              <div>
                                <p className="flex justify-between">
                                  <span className="font-semibold">
                                    Total (incl. GST) :
                                  </span>{" "}
                                  <span>
                                    {formatIndianNumber(
                                      item.spigst * item.quantity
                                    )}
                                  </span>
                                </p>
                              </div>
                            </div>

                            {/* right */}
                            <div className="w-2/5 pl-4">
                              <div className="mb-2">
                                <p className="flex justify-between">
                                  <span className="font-semibold">
                                    Quantity :
                                  </span>
                                </p>
                              </div>
                              <div className="flex flex-row justify-center items-center">
                                <div className="w-6">
                                  <button
                                    className="w-full bg-blue-600 rounded-tl-sm rounded-bl-sm text-white"
                                    onClick={(e) =>
                                      handleQuantityChangeButton(
                                        e,
                                        item,
                                        index,
                                        "-"
                                      )
                                    }
                                    disabled={raised}
                                  >
                                    -
                                  </button>
                                </div>
                                <div className="w-10 flex justify-center items-center">
                                  <input
                                    type="text"
                                    value={item.quantity}
                                    className="w-10 text-center"
                                    onChange={(e) =>
                                      handleQuantityChange(e, item, index)
                                    }
                                    onBlur={(e) =>
                                      handleQuantityBlur(e, item, index)
                                    }
                                    disabled={raised}
                                  />
                                </div>
                                <div className="w-6">
                                  <button
                                    className="w-full bg-blue-600 rounded-tr-sm rounded-br-sm text-white"
                                    onClick={(e) =>
                                      handleQuantityChangeButton(
                                        e,
                                        item,
                                        index,
                                        "+"
                                      )
                                    }
                                    disabled={raised}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionBody>
                      </Accordion>
                      
                    </div>
                  );
                })}
{parseFloat(quotation.deliveryCharges)>0 && (                              <div style={{ 
                backgroundColor: "#f0f0f0",
                padding: "10px",
                marginTop: "10px",
                border: "1px solid #ccc",
                textAlign:"center"
              }}>
                Applicable delivery charges: {formatIndianNumber(parseFloat(quotation.deliveryCharges))}
              </div>)}
              </div>

              {/* bottom footer */}

              <div className="w-full fixed flex flex-row bottom-0 z-50 bg-gray-100 pl-2 pr-2 shadow-md rounded-t-lg">
                {/* amount */}
                <div className="w-1/3 p-1">
                  {!discountFlag && (
                    <div>
                      <div>
                        <h3 className="font-semibold text-sm">
                          Quotation Amount
                        </h3>
                      </div>
                      <div>
                        <h1 className="font-bold text-base">
                          {formatIndianNumber(quotation.totalAmount)}
                        </h1>
                      </div>
                    </div>
                  )}

                  {discountFlag && (
                    <div>
                      <div className="flex flex-row">
                        <h3 className=" line-through	text-gray-800 text-sm font-light">
                          {formatIndianNumber(quotation.totalAmount)}
                        </h3>
                        <h3 className="ml-1 flex items-center text-xs bg-orange-300 p-1 rounded-md">
                          -{parseFloat(discount.discountInPercent).toFixed(2)}%
                        </h3>
                      </div>
                      <div>
                        <h1 className="font-bold text-base">
                          {formatIndianNumber(discount.discountedAmount)}
                        </h1>
                      </div>
                    </div>
                  )}
                </div>

                {/* buttons */}
                <div className="flex items-center justify-center p-1 w-1/3">
                  {!raised && (
                    <Button
                      color="green"
                      size="lg"
                      className="bg-green-600 normal-case text-md font-bold py-2 px-4"
                      onClick={(e) => {
                        if (paymentTermsFlag) {
                          handlePaymentTermsDialog();
                        } else {
                          handleTncDialog();
                        }
                        logEvent(e, "NA", "raise_order_home", "button");
                      }}
                      disabled={
                        raised || parseFloat(quotation.totalAmount) === 0
                      }
                    >
                      Raise Order
                    </Button>
                  )}
                  {raised && (
                    <Button
                      color="green"
                      size="lg"
                      className="bg-gray-600 normal-case text-md font-bold py-2 px-4"
                      onClick={handleRaisedDialog}
                    >
                      Order Raised
                    </Button>
                  )}
                </div>

                {/* downloads */}
                <div className="p-1 w-1/3 flex items-center justify-center">
                  {/* <div className={attachmentFlag ? "w-1/2" : "w-full"}>
                    <div className="bg-gray-200 hover:bg-gray-300 py-1 text-sm rounded shadow-lg flex items-center justify-center border border-gray-400 text-blue-800 font-semibold">
                      {quotation.totalAmount === 0 ? (
                        <X />
                      ) : (
                        handleDownloadPdf()
                      )}
                    </div>
                  </div>

                  {attachmentFlag && (
                    <div className="w-1/2">
                      <button
                        className="bg-gray-200 hover:bg-gray-300 py-1 text-sm rounded shadow-lg flex justify-center border border-gray-400 text-blue-800 font-semibold w-full ml-2"
                        onClick={handleAttachmentsDialog}
                      >
                        <FileStack />{" "}
                        <Paperclip className="w-4 ml-2 text-red-300" />
                      </button>
                    </div>
                  )} */}
                  <div>
                    <Menu
                      placement="top-end"
                      open={openMenu}
                      handler={setOpenMenu}
                    >
                      <MenuHandler>
                        <Button className="bg-inherit border-none shadow-none normal-case text-base font-base underline text-blue-600">
                          <Download />
                        </Button>
                      </MenuHandler>
                      <MenuList>
                        {openMenu && quotation.totalAmount !== 0 && (
                          <MenuItem
                            onClick={(e) => {
                              logEvent(e, "NA", "pdf_download", "button");
                            }}
                          >
                            {handleDownloadPdf()}
                          </MenuItem>
                        )}
                        {attachmentFlag && (
                          <MenuItem onClick={handleAttachmentsDialog}>
                            <span className="flex flex-row">
                              Attachments{" "}
                              <Paperclip className="w-4 ml-2 text-red-300" />
                            </span>
                          </MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  </div>
                </div>
              </div>

              {/* bd calling */}
              {/* {bdDetails && ( */}
              <div className="fixed bottom-20 right-6 flex flex-col items-center justify-center">
                <div>
                  <a
                    href={`tel:${bdDetails ? bdDetails.tel : "+918000811160"}`}
                    onClick={(event) => {
                      logEvent(event, "NA", "call_now_clicked", "button");
                    }}
                  >
                    <img
                      src="/image-removebg-preview.png"
                      alt="Call"
                      className="w-20"
                    />
                    <p className="text-xs text-center font-semibold text-gray-700	">
                      Call Now
                    </p>
                  </a>
                </div>
              </div>
              {/* )} */}
            </div>
          )}

          {/* dialogs */}
          {!loading && !notFound && (
            <div>
              <Dialog
                open={showMoreDialog}
                handler={() => {
                  handleShowMoreDialog();
                }}
              >
                <DialogHeader>{showMoreHeader}</DialogHeader>
                <DialogBody className="p-0 pl-4 pr-4 h-[30rem] overflow-scroll">
                  <div
                    className={
                      showMoreHeader === "Description"
                        ? "whitespace-pre-line"
                        : ""
                    }
                  >
                    {showMoreBody}
                  </div>
                </DialogBody>
                <DialogFooter>
                  <Button
                    variant="gradient"
                    color="green"
                    onClick={() => {
                      handleShowMoreDialog();
                    }}
                  >
                    <span>Done</span>
                  </Button>
                </DialogFooter>
              </Dialog>

              {/* attachments */}
              {attachmentFlag && (
                <Dialog
                  open={showAttachments}
                  handler={handleAttachmentsDialog}
                >
                  <DialogHeader>Attachments</DialogHeader>
                  <DialogBody className="p-0 pl-4 pr-4 h-[30rem] overflow-scroll">
                    <div>
                      <ul>
                        {quotation.attachmentDetails.map(
                          (attachment, index) => (
                            <li key={index}>
                              <a
                                href="#"
                                download={`${attachment.attachmentTitle}.pdf`}
                                onClick={(event) => {
                                  event.preventDefault();
                                  downloadFile(
                                    attachment.attachmentLink,
                                    attachment.attachmentTitle
                                  );
                                }}
                                className="text-blue-800"
                              >
                                {attachment.attachmentTitle}
                              </a>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </DialogBody>
                  <DialogFooter>
                    <Button
                      variant="gradient"
                      color="green"
                      onClick={handleAttachmentsDialog}
                    >
                      <span>Done</span>
                    </Button>
                  </DialogFooter>
                </Dialog>
              )}

              {/* Terms  & conditions */}
              <Dialog open={showTncDialog} handler={handleTncDialog} size="lg">
                <DialogHeader>Terms & Conditions</DialogHeader>
                <DialogBody className="p-0 h-[30rem] overflow-scroll">
                  <div className="pl-10 pr-6">
                    <ul style={{ listStyleType: "disc" }}>
                      {tncPoints.map((point, index) => (
                        <li key={index}>{point}</li>
                      ))}
                    </ul>
                  </div>
                </DialogBody>
                <DialogFooter>
                  <Button
                    variant="gradient"
                    color="green"
                    onClick={() => {
                      handleTncDialog();

                      if (paymentTermsFlag && termAccepted) {
                        handleOtpDialog();
                      } else {
                        handlePaymentTermsDialog();
                      }

                      if (!paymentTermsFlag) {
                        sendOTP();
                        handleOtpDialog();
                      }
                    }}
                  >
                    <span>Understood</span>
                  </Button>
                </DialogFooter>
              </Dialog>

              {/* Payment terms */}

              {paymentTermsFlag && (
                <Dialog
                  open={showPaymentTerms}
                  handler={handlePaymentTermsDialog}
                  size="lg"
                >
                  <DialogHeader>Payment Terms</DialogHeader>
                  <DialogBody className="p-0">
                    <div className="pl-6 pr-6">
                      <div className="flex justify-center">
                        <table className="text-black text-sm border">
                          <thead className="text-center bg-gray-400">
                            <tr>
                              <th className="p-2 border">Payment Terms</th>
                              <th className="p-2 border">Amount (Rs.)</th>
                              <th className="p-2 border">Percentage (%)</th>
                            </tr>
                          </thead>
                          <tbody className="text-center">
                            {quotation.paymentTerms[0].paymentTermsAmount >
                              0 && (
                              <tr>
                                <th className="bg-gray-300 body">Advance</th>
                                <td className="border">
                                  {formatIndianNumber(
                                    ((quotation.totalAmount -
                                      (quotation.totalAmount *
                                        parseFloat(
                                          getDiscount().discountInPercent
                                        )) /
                                        100) *
                                      quotation.paymentTerms[0]
                                        .paymentTermsPercentage) /
                                      100
                                  )}
                                </td>
                                <td className="border">
                                  {quotation.paymentTerms[0].paymentTermsPercentage.toFixed(
                                    2
                                  )}
                                  %
                                </td>
                              </tr>
                            )}
                            {quotation.paymentTerms[1].paymentTermsAmount >
                              0 && (
                              <tr>
                                <th className="bg-gray-300 border">
                                  On Delivery
                                </th>
                                <td className="border">
                                  {formatIndianNumber(
                                    ((quotation.totalAmount -
                                      (quotation.totalAmount *
                                        parseFloat(
                                          getDiscount().discountInPercent
                                        )) /
                                        100) *
                                      quotation.paymentTerms[1]
                                        .paymentTermsPercentage) /
                                      100
                                  )}
                                </td>
                                <td className="border">
                                  {quotation.paymentTerms[1].paymentTermsPercentage.toFixed(
                                    2
                                  )}
                                  %
                                </td>
                              </tr>
                            )}
                            {quotation.paymentTerms[2].paymentTermsAmount >
                              0 && (
                              <tr>
                                <th className="bg-gray-300 border">
                                  On Installation
                                </th>
                                <td className="border">
                                  {formatIndianNumber(
                                    ((quotation.totalAmount -
                                      (quotation.totalAmount *
                                        parseFloat(
                                          getDiscount().discountInPercent
                                        )) /
                                        100) *
                                      quotation.paymentTerms[2]
                                        .paymentTermsPercentage) /
                                      100
                                  )}
                                </td>
                                <td className="border">
                                  {quotation.paymentTerms[2].paymentTermsPercentage.toFixed(
                                    2
                                  )}
                                  %
                                </td>
                              </tr>
                            )}
                            {quotation.paymentTerms[3].paymentTermsAmount >
                              0 && (
                              <tr>
                                <th className="bg-gray-300 border">
                                  {quotation.paymentTerms[3].duration} Days
                                  Credit
                                </th>
                                <td className="border">
                                  {formatIndianNumber(
                                    ((quotation.totalAmount -
                                      (quotation.totalAmount *
                                        parseFloat(
                                          getDiscount().discountInPercent
                                        )) /
                                        100) *
                                      quotation.paymentTerms[3]
                                        .paymentTermsPercentage) /
                                      100
                                  )}
                                </td>
                                <td className="border">
                                  {quotation.paymentTerms[3].paymentTermsPercentage.toFixed(
                                    2
                                  )}
                                  %
                                </td>
                              </tr>
                            )}
                            {quotation.paymentTerms[4].paymentTermsAmount >
                              0 && (
                              <tr>
                                <th className="bg-gray-300 border">
                                  {quotation.paymentTerms[4].duration} Months
                                  EMI
                                </th>
                                <td className="border">
                                  {formatIndianNumber(
                                    ((quotation.totalAmount -
                                      (quotation.totalAmount *
                                        parseFloat(
                                          getDiscount().discountInPercent
                                        )) /
                                        100) *
                                      quotation.paymentTerms[4]
                                        .paymentTermsPercentage) /
                                      100
                                  )}
                                </td>
                                <td className="border">
                                  {quotation.paymentTerms[4].paymentTermsPercentage.toFixed(
                                    2
                                  )}
                                  %
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="text-black flex items-center mt-2">
                        <label className="flex items-center text-sm">
                          <input
                            type="checkbox"
                            name="checkbox"
                            checked={termAccepted}
                            onChange={(e) => setTermAccepted(e.target.checked)}
                            className="mr-2 h-4 w-4"
                          />
                          I have read and agree to the{"  "}
                          <a
                            href="#"
                            className="text-blue-600 ml-1"
                            onClick={(e) => {
                              handlePaymentTermsDialog();
                              handleTncDialog();
                            }}
                          >
                            Terms & Conditions.
                          </a>
                        </label>
                      </div>
                    </div>
                  </DialogBody>
                  <DialogFooter>
                    <Button
                      variant="gradient"
                      color="green"
                      onClick={() => {
                        handlePaymentTermsDialog();
                        sendOTP();
                        handleOtpDialog();
                      }}
                      disabled={!termAccepted}
                    >
                      <span>Continue</span>
                    </Button>
                  </DialogFooter>
                </Dialog>
              )}

              {/* OTP */}
              <Dialog open={showOtpDialog} size="md">
                <DialogHeader className="text-black">Raise Order</DialogHeader>
                <DialogBody className="p-0">
                  {wrongOtp && (
                    <div className="flex flex-col justify-center items-center text-center mt-1">
                      <p className="text-red-600">Wrong OTP!</p>
                    </div>
                  )}
                  {raising && (
                    <div className="flex flex-col justify-center items-center text-center mt-1">
                      <p className="text-green-600">Raising Order...</p>
                      <p className="text-red-600">
                        Please do not refresh this page!
                      </p>
                    </div>
                  )}
                  <div className="pl-10 pr-6 flex flex-col justify-center">
                    <div>
                      <h1 className="text-center text-black">
                        An OTP has been sent to +91
                        {quotation.queryDetails.phonePoc}
                      </h1>
                    </div>
                    <div className="flex flex-col justify-center items-center text-center">
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderInput={(props) => (
                          <input
                            {...props}
                            style={{
                              marginLeft: "5px",
                              marginRight: "5px",
                              width: "40px",
                              height: "40px",
                              border: "1px solid #000000",
                              borderRadius: "5px",
                              padding: "2px",
                              textAlign: "center",
                            }}
                            disabled={raising}
                          />
                        )}
                        inputType="number"
                      />
                    </div>
                  </div>
                </DialogBody>
                <DialogFooter>
                  <Button
                    variant="outlined"
                    color="black"
                    onClick={(e) => {
                      handleOtpDialog();
                      setOtp("");
                      logEvent(e, "NA", "raise_order_cancel", "button");
                    }}
                    className="mr-2"
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button
                    variant="gradient"
                    color="green"
                    onClick={(e) => {
                      // handleOtpDialog();
                      verifyOTP();
                      logEvent(e, "NA", "verify_otp", "button");
                    }}
                  >
                    <span>Verify OTP</span>
                  </Button>
                </DialogFooter>
              </Dialog>

              {/* Raised */}

              {!loading && raised && (
                <Dialog
                  open={showRaisedDialog}
                  handler={handleRaisedDialog}
                  size="md"
                >
                  <DialogBody className="p-0">
                    <div className="p-6 flex flex-col items-center justify-center">
                      <div className="flex flex-col items-center justify-center">
                        <div className="w-28">
                          <img
                            src="/2ccc867d887c1ca12670bab8084b0936.png"
                            alt="Success"
                          />
                        </div>
                        <div className="mt-4">
                          <h1 className="text-center text-black font-extrabold text-2xl">
                            Order Raised Successfully!
                          </h1>
                          <h2 className="text-center text-black font-semibold">
                            Our Team will connect with you soon.
                          </h2>
                          <p className="text-center text-black text-sm">
                            You can make any applicable advance payment to
                            confirm the order.
                          </p>
                        </div>
                      </div>

                      <div className="mt-4 flex flex-col items-center justify-center">
                        <div className="items-center justify-center flex flex-col text-black">
                          <div className="mb-1">
                            <h1 className="font-bold">Account Details:</h1>
                          </div>
                          <div className="text-center">
                            <p>
                              <strong className="font-bold">Name:</strong> Savdo
                              Technologies Pvt. Ltd.
                            </p>
                            <p>
                              <strong className="font-bold">
                                Account Number:
                              </strong>{" "}
                              50200069329058
                            </p>
                            <p>
                              <strong className="font-bold">IFS Code:</strong>{" "}
                              HDFC0003654
                            </p>
                          </div>
                        </div>

                        {paymentInfo && quotation ? (
                          quotation.paymentTerms[0].paymentTermsAmount <
                          500000 ? (
                            <div
                              onClick={() =>
                                (window.location.href = paymentInfo.short_url)
                              }
                              className="flex flex-row border border-gray-300 shadow-lg p-2 rounded-md cursor-pointer mt-4"
                            >
                              {paymentInfo.amount - paymentInfo.amount_paid >
                              0 ? (
                                <>
                                  <div
                                    className="w-20 h-20 flex items-center justify-center"
                                    style={{ padding: "10px" }}
                                  >
                                    <img src="/rzp.png" alt="rzp Logo" />
                                  </div>
                                  <div className="ml-4">
                                    <div className="mb-1">
                                      <h1
                                        className="text-lg font-semibold"
                                        style={{ color: "#0d94fb" }}
                                      >
                                        Pay with Razorpay
                                      </h1>
                                    </div>
                                    <div className="text-sm">
                                      <p>
                                        <span
                                          className="font-semibold"
                                          style={{ color: "#012652" }}
                                        >
                                          Due Advance: ₹
                                          {(paymentInfo.amount -
                                            paymentInfo.amount_paid) /
                                            100}
                                        </span>
                                      </p>
                                      <p>Pay advance now to confirm!</p>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="flex items-center justify-center w-20 h-20"
                                    style={{ padding: "10px" }}
                                  >
                                    <img src="/green-tick.png" alt="tick" />
                                  </div>
                                  <div className="ml-4 flex flex-col justify-center">
                                    <div className="mb-1">
                                      <h1
                                        className="text-lg font-semibold"
                                        style={{ color: "#00c04b" }}
                                      >
                                        Advance Paid!
                                      </h1>
                                    </div>
                                    <div className="text-sm">
                                      <p>
                                        <span
                                          className="font-semibold"
                                          style={{ color: "#012652" }}
                                        >
                                          Amount Paid: ₹
                                          {paymentInfo.amount_paid / 100}
                                        </span>
                                      </p>
                                      <p>Our team will reach you shortly.</p>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          ) : (
                            <div
                              onClick={() =>
                                window.open(
                                  "upi://pay?pa=savdotechnologiespvt.62739774@hdfcbank&pn=Zoplar",
                                  "_blank"
                                )
                              }
                              className="flex flex-row border border-gray-300 shadow-lg p-2 rounded-md cursor-pointer mt-4"
                            >
                              {/* left */}
                              <div className="w-20 h-20 flex items-center justify-center">
                                <img src="/upi.png" alt="UPI Logo" />
                              </div>
                              <div className="ml-4">
                                <div className="mb-1">
                                  <h1 className="text-2xl font-semibold text-green-600">
                                    Pay with UPI
                                  </h1>
                                </div>

                                <div className="text-sm">
                                  <p className="font-semibold">
                                    Savdo Technologies Pvt. Ltd.
                                  </p>
                                  <p>
                                    <span className="font-semibold">
                                      UPI ID:
                                    </span>{" "}
                                    savdotechnologiespvt.62739774@hdfcbank
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                        ) : (
                          <div className="text-sm">Loading...</div>
                        )}

                        {queryParams &&
                          Object.entries(queryParams).length > 0 && (
                            <p key={Object.entries(queryParams)[0][0]}>
                              Payment Id:{" "}
                              <strong>
                                {Object.entries(queryParams)[0][1]}
                              </strong>
                            </p>
                          )}
                      </div>
                    </div>
                  </DialogBody>
                  <DialogFooter>
                    <Button
                      variant="gradient"
                      color="green"
                      onClick={() => {
                        handleRaisedDialog();
                      }}
                    >
                      <span>Okay</span>
                    </Button>
                  </DialogFooter>
                </Dialog>
              )}
              <Dialog
                open={showPayReceipt}
                handler={handlePayReceipt}
                size="xxl"
              >
                <DialogBody className="p-0">
                  <div
                    className="max-w-full overflow-x-auto"
                    style={{ padding: "10px" }}
                  >
                    <h2 className="text-lg font-semibold mb-4 text-center">
                      Payment Receipt
                    </h2>
                    <p className="text-xs mb-4 text-center">
                      You can optionally capture a screenshot of this payment
                      receipt for your future reference.
                    </p>
                    <table className="table-auto mx-auto border-collapse border border-black text-xs">
                      <thead>
                        <tr className="border border-black">
                          <th className="border border-black px-4 py-2 text-left">
                            Parameter
                          </th>
                          <th className="border border-black px-4 py-2 text-left">
                            Value
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(queryParams).map(([key, value]) => (
                          <tr key={key} className="border border-black">
                            <td className="border border-black px-4 py-2">
                              {key}
                            </td>
                            <td className="border border-black px-4 py-2 break-words">
                              {value}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </DialogBody>
                <DialogFooter>
                  <Button
                    variant="gradient"
                    color="green"
                    onClick={() => {
                      handlePayReceipt();
                    }}
                  >
                    <span>Okay</span>
                  </Button>
                </DialogFooter>
              </Dialog>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(Desktop);
