import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./global.css";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container); // Use createRoot instead of ReactDOM.render

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

reportWebVitals();
