import React, { useState, useEffect } from "react";
import { useLocation, Routes, Route } from "react-router-dom"; // Import Routes
import Home from "./pages/Home";
import Desktop from "./pages/Desktop";
import Fof from "./pages/404";
import PDFView from "./pages/PDFView";

const App = () => {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const updateScreenWidth = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    updateScreenWidth();
    window.addEventListener("resize", updateScreenWidth);

    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  const isQuotationPage = location.pathname.startsWith("/quotation/");

  return (
    <Routes>
      {" "}
      {/* Wrap your Route components with Routes */}
      <Route path="/pdf/*" element={<PDFView />} />
      <Route
        path="/quotation/*"
        element={isQuotationPage ? <Desktop isMobile={isMobile} /> : <Fof />}
      />
      <Route path="/*" element={<Fof />} />
    </Routes>
  );
};

export default App;
